import React from 'react';
import Image from 'next/image';
import roposoLogo from './roposo-logo.svg';
import lineStreak from './line-streak.svg';
import useDomainInfo from 'hooks/determine-client-domain';

interface HeaderProps {
  pageTitle: string;
}

const Header = ({ pageTitle }: HeaderProps) => {
  const { isShopTab, hasWindow } = useDomainInfo();
  if (hasWindow && isShopTab) {
    return (
      <div className='mt-16 w-full'>
        <div className='text-center'>
          <Image src={roposoLogo} />
        </div>
        <div className='mb-2 py-1 text-center'>
          <Image src={lineStreak} />
        </div>
        <div className='mb-4 mt-2 w-full text-left text-2xl font-bold leading-none text-neutral-950'>
          {pageTitle}
        </div>
      </div>
    );
  }
  return (
    <div className='mb-4 mt-32 w-full text-left text-2xl font-bold leading-none text-neutral-950'>
      {pageTitle}
    </div>
  );
};

export default Header;
